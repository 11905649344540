import React, { useState } from "react";

import MainPage from "../components/MainPage";
import Header from "../components/Header";
import Button from "../components/Button";
import ConnectModal from "../components/ConnectModal";

import logo from "../images/DRx-logo.png";
import rex from "../images/Murdered-Out-Cap.png";
// import cap from "../images/god_of_war_cap.png";

export default function ConnectWallet({ dimensions }) {
    const [connectModalOpen, setConnectModalOpen] = useState(false);

    const closeModal = () => {
        setConnectModalOpen(false);
    };

    const openModal = () => {
        setConnectModalOpen(true);
    };

    return (
        <div className="min-w-screen min-h-screen bg-drbg bg-cover bg-fixed">
            <div>
                {connectModalOpen && <ConnectModal closeModal={closeModal} />}
                <Header
                    menuItems={["Home", "Drug inspector", "Drug store"]}
                    rightArea={null}
                    showSocial={true}
                    externalLink={false}
                    routes={["https://drugreceipts.com/", "/thelab", "https://drx.store"]}
                />
                <MainPage>
                    <div className="flex flex-col h-full mx-auto pt-[70px] ">
                        <div className="flex items-center justify-center w-full h-full gap-8 ">
                            <div className="w-full lg:w-1/2 my-10">
                                <img className="w-[440px] my-8 " src={logo} alt="logo" />
                                <p className="font-open-sans text-white text-2xl font-light">
                                    Connect your wallet to go Behind the Counter and get access to
                                    content and perks reserved exclusively for Drug Receipts NFT
                                    holders. That’s what I’m snuggin’ bout!
                                </p>

                                <div className="gap-4 my-10 text-[26px] mt-[40px] md:pr-[10%]">
                                    <Button clickFunction={openModal} className="">
                                        Connect Wallet
                                    </Button>
                                    <a href="https://opensea.io/collection/drugreceipts"
                                        target="_blank"
                                        rel="noreferrer"><Button className="">
                                            Become a Holder
                                        </Button>
                                    </a>
                                </div>
                            </div>

                            {dimensions.width > 900 && (
                                <img
                                    src={rex}
                                    className="w-1/2"
                                    alt="Rex Character"
                                    width={470}
                                    height={505}
                                />
                            )}
                        </div>
                    </div>
                </MainPage>
            </div>
        </div>
    );
}
