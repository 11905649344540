import React from "react";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category = "Download images") => {
    const eventTracker = (action = "action", label = "label") => {
        ReactGA.event({ category, action, label });
    }
    return eventTracker;
}

export default useAnalyticsEventTracker;