import { ReactComponent as Close } from "../images/X.svg";
import cap from "../images/Murdered-Out-Cap.png";

import WalletProtected from "../layout/WalletProtected";
import Wallet from "../pages/Wallet";

export default function ConnectModal({ closeModal }) {
    return (
        <div
            className="fixed flex flex-wrap justify-center items-center 
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full"
        >
            <div
                className="flex flex-col fixed justify-center items-center p-5 
            w-[80%] md:w-[50%] lg:w-[80%] xl:w-[60%] max-w-[1160px] 3xl:h-[60%] bg-dmbg bg-cover rounded-3xl overflow-y-auto"
            >
                <div
                    onClick={closeModal}
                    className="flex fixed sm:absolute right-0 top-0 p-3 m-2 
                    justify-center items-center h-14 w-14 rounded-full
                    hover:cursor-pointer transform hover:border-4 "
                >
                    <Close className="" />
                </div>

                <div className="text-white h-full justify-center text-center items-center w-[75%]">
                    <h1 className="font-pressio-condensed text-4xl sm:text-5xl mt-4">
                        CONNECT WALLET
                    </h1>
                    <div className="block lg:grid grid-flow-row grid-cols-2">
                        <div className="col-span-1">
                            <img
                                className="w-[75%] max-w-[350px] min-w-[200px] mx-auto my-10 xs:my-14"
                                src={cap}
                                alt="cap"
                            />
                        </div>
                        <div className="py-0 xs:py-8 col-span-1 flex items-center justify-center">
                            <WalletProtected>
                                <Wallet />
                            </WalletProtected>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
