import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";

import JSZip from "jszip";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";

import InspectorHeader from "../components/InspectorHeader";
import MainPage from "../components/MainPage";
import TheLabNFTs from "../components/TheLabNFTs";
import * as AssetAPI from "../utils/assetApi";
import Button from "../components/Button";
import Button1 from "../components/Button1";
import rarityImg from "../images/raritysnoper.png";
import openseaImg from "../images/OpenSea.png";

import originBtnImg from "../images/Download buttons/origin.png";
import onetooneBtnImg from "../images/Download buttons/onetoone.png";
import biggerBtnImg from "../images/Download buttons/bigger.png";
import landscapeBtnImg from "../images/Download buttons/landscape.png";
import portraitBtnImg from "../images/Download buttons/portrait.png";
import bkgBtnImg from "../images/Download buttons/bkg.png";

import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';

import { Scrollbars } from 'react-custom-scrollbars';

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#a97fb1'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

export default function TheLab() {
    const wallet = useWeb3React();

    const [selectedImages, setSelectedImages] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [selectedName, setSelectedName] = useState([]);

    const gaEventTracker = useAnalyticsEventTracker('Download images');

    const downloadZip = () => {
        if (selectedImages[0]) {
            let links = [];
            for (let i = 1; i <= 5; i++) {
                let img_url = getImageUrl(selectedImages[0], i);
                links.push(img_url);
            }
            let img_url = getImageUrl(selectedImages[0], 6);
            let bg_color = attributes[attributes.length - 1].value ?? '';
            let filename = '';
            bg_color = bg_color.toString().replace(' ', '_');
            filename = bg_color + '.png';
            img_url = img_url + "/" + filename;
            links.push(img_url);
            links.push(getImageUrl(selectedImages[0], 7));
            var zip = new JSZip();
            var count = 0;
            var zipFilename = "DRx_#" + selectedImages[0] + "_all.zip";
            gaEventTracker("Download" + zipFilename);
            links.forEach(function (url, i) {
                var filename = links[i].substring(links[i].lastIndexOf("/") + 1);
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err) {
                        throw err;
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    if (count === links.length) {
                        zip.generateAsync({ type: "blob" }).then(function (content) {
                            FileSaver.saveAs(content, zipFilename);
                        });
                    }
                });
            });
        }
    };

    const downloadPngZip = () => {
        if (selectedImages[0]) {
            let links = [];
            let img_url_no_glow = getImageUrl(selectedImages[0], 1);
            links.push(img_url_no_glow);
            let img_url = getImageUrl(selectedImages[0], 7);
            links.push(img_url);
            var zip = new JSZip();
            var count = 0;
            var zipFilename = "DRx_#" + selectedImages[0] + "_png.zip";
            gaEventTracker("Download" + zipFilename);
            links.forEach(function (url, i) {
                var filename = links[i].substring(links[i].lastIndexOf("/") + 1);
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err) {
                        throw err;
                    }
                    zip.file(filename, data, { binary: true });
                    count++;
                    if (count === links.length) {
                        zip.generateAsync({ type: "blob" }).then(function (content) {
                            FileSaver.saveAs(content, zipFilename);
                        });
                    }
                });
            });
        }
    };

    const downloadImage = async (type) => {
        if (selectedImages[0]) {
            let img_url = getImageUrl(selectedImages[0], type);
            var filename = img_url.substring(img_url.lastIndexOf("/") + 1);
            if (type === 6) {
                let bg_color = attributes[attributes.length - 1].value ?? '';
                bg_color = bg_color.toString().replace(' ', '_');
                filename = bg_color + '.png';
                img_url = img_url + "/" + filename;
            }
            gaEventTracker("Download" + filename);
            fetch(img_url, {
                method: "GET",
                headers: {},
            })
                .then((response) => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = filename;
                        link.click();
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const addImage = (nft) => {
        setSelectedImages([nft]);
        getTraits(nft);
    };

    const removeImage = (nft) => {
        setSelectedImages([]);
        setAttributes([]);
        setSelectedName("");
    };

    const getImageUrl = (tokenId, type = 0) => {
        return AssetAPI.getImageUrl(tokenId, type);
    };

    const getTraits = async (tokenId) => {
        let tokenInfo = await AssetAPI.getTokenInfo(tokenId);
        let temp_attribute = (await tokenInfo) ? await tokenInfo.attributes : [];
        setAttributes(temp_attribute);
        if (temp_attribute === []) {
            setSelectedName("");
        } else {
            let temp_name =
                temp_attribute[1]["value"] + " " + temp_attribute[0]["value"];
            setSelectedName(temp_name);
        }
    };

    return (
        <div className="min-w-screen min-h-screen bg-dibg bg-cover bg-fixed">
            <div>
                <InspectorHeader
                    menuItems={['Home', 'Drug inspector', 'Drug store']}
                    rightArea={null}
                    showSocial={true}
                    externalLink={false}
                    routes={['https://drugreceipts.com/', '/thelab', 'https://drx.store']}
                />
                <MainPage>
                    <div className="flex flex-col w-full mt-[80px] mx-auto p-4">
                        <div className=" w-full">
                            <div className="text-white my-10">
                                <h1 className="font-pressio-condensed text-[44px]  font-semibold">
                                    DRUG INSPECTOR
                                </h1>
                                <p className="font-open-sans text-xl w-full md:w-[80%] lg:w-[60%]">
                                    Welcome to the Drug Inspector. Here you will find details about your Drug Receipts NFT. You can also access unique downloadable content including portrait and landscape versions of your PFP and PNGs of your character on transparent backgrounds.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row w-full mx-auto pb-12 ">
                            <div className="w-full h-full  md:w-[55.5%] md:px-0">
                                <div className="grid md:grid-cols-2 gap-2 bg-white rounded-md">
                                    <div>
                                        <div className="p-4 bg-white rounded-md">
                                            <div className="w-full bg-phbg aspect-square bg-cover h-full">
                                                {selectedImages[0] && (
                                                    <img
                                                        className="w-full"
                                                        src={getImageUrl(selectedImages[0])}
                                                        alt={`Drug receipt #${selectedImages[0]}`}
                                                    />
                                                )}
                                            </div>
                                            <div className="mt-2 font-open-sans text-[#9c69a3] text-[14px] leading-snug">
                                                Click and choose individual assets below or select “download all” to get everything in a single ZIP file.
                                            </div>
                                            <div className="mt-3 grid grid-cols-6 grid-flow-row items-center justify-center">
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadPngZip()}
                                                        src={originBtnImg}
                                                        alt="origin"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/origin_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/origin.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadImage(2)}
                                                        src={onetooneBtnImg}
                                                        alt="onetoone"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/onetoone_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/onetoone.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadImage(3)}
                                                        src={biggerBtnImg}
                                                        alt="bigger"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/bigger_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/bigger.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadImage(4)}
                                                        src={landscapeBtnImg}
                                                        alt="landscape"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/landscape_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/landscape.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadImage(5)}
                                                        src={portraitBtnImg}
                                                        alt="portrait"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/portrait_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/portrait.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                                <div className="col-span-1 flex justify-center">
                                                    <img
                                                        onClick={() => downloadImage(6)}
                                                        src={bkgBtnImg}
                                                        alt="bkg"
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/bkg_hover.png"))
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.src = require("../images/Download buttons/bkg.png"))
                                                        }
                                                        className="w-[40px] h-[40px] md:w-[26px] md:h-[26px] lg:w-[40px] lg:h-[40px] cursor-pointer"
                                                    ></img>
                                                </div>
                                            </div>
                                            <div className="grid mt-2">
                                                <Button1
                                                    clickFunction={downloadZip}
                                                    className="mt-2 w-full md:px-0 p-0"
                                                >
                                                    <span className="text-[24px]">Download all</span>
                                                </Button1>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-4 rounded-md font-open-sans">
                                        <div className="flex justify-between mb-6 text-lg text-[#512857]">
                                            <div>
                                                <div className="font-bold text-2xl">
                                                    DRx #{selectedImages[0] && selectedImages[0]}
                                                </div>
                                                <div className="font-semibold text-[16px]">
                                                    {selectedName}
                                                </div>
                                            </div>
                                            <div className="flex flex-row space-x-2">
                                                <a
                                                    href={
                                                        !selectedImages[0]
                                                            ? "https://opensea.io/collection/drugreceipts"
                                                            : "https://opensea.io/assets/ethereum/0x00ea2894fe840f105ab99a8f8f75b1f17e94843a/" +
                                                            selectedImages[0]
                                                    }
                                                    className="hover:underline"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={openseaImg} className="w-[24px]" alt="opensea"></img>
                                                </a>
                                                <a
                                                    href={
                                                        "https://raritysniper.com/drug-receipts/" +
                                                        (selectedImages[0] ? selectedImages[0] : "")
                                                    }
                                                    className="hover:underline"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img src={rarityImg} className="w-[24px]" alt="rarity"></img>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="text-[12px] space-y-0 border-b-2 border-b-[#c3a1c8]">
                                            {attributes &&
                                                attributes.map((attribute) => (
                                                    <div
                                                        key={attribute.trait_type}
                                                        className="grid grid-cols-2 gap-x-2 border-t-2 border-t-[#c3a1c8] min-h-[28px] items-center"
                                                    >
                                                        <div className="text-[#9c69a3] text-[10px] uppercase">
                                                            {attribute.trait_type}
                                                        </div>
                                                        <div className="text-[#512857] text-[13px]">
                                                            {attribute.value}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="order-first md:order-last mb-4 w-full max-w-full mt-2 sm:mt-0 sm:ml-2 rounded-md h-[260px] md:h-[490px] bg-white p-2 pr-1
                        overflow-x-auto sm:overflow-x-hidden md:w-[44.5%]"
                            >
                                <CustomScrollbars>
                                    {/* autoHide autoHideTimeout={500} autoHideDuration={200} */}
                                    <div className="pr-[11px]">
                                        <TheLabNFTs
                                            // walletAddress={
                                            //     "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9" /*TODO: wallet.account*/
                                            // }
                                            walletAddress={wallet.account}
                                            className="scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"
                                            onSelect={addImage}
                                            onDeselect={removeImage}
                                        ></TheLabNFTs>
                                    </div>
                                </CustomScrollbars>
                            </div>
                        </div>
                    </div>
                </MainPage>
            </div>
        </div>
    );
}
