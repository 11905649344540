import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect, walletlink } from "../wallet/connectors.js";

import Button from "../components/Button";

export default function WalletProtected(props) {
  const wallet = useWeb3React();
  const location = useLocation();
  const navigate = useNavigate()


  // useEffect(() => {
  //   if (!wallet.active) {
  //     navigate("/");
  //   }
  // }, []); //eslint-disable-line


  useEffect(() => {
    if (wallet.active) {
      navigate("/thelab");
    }else{
      navigate("/");
    }
    // console.log("123123");
  }, [wallet]); //eslint-disable-line

  return (
    <React.Fragment>
      {wallet.account ? (
        props.children
      ) : (
        <React.Fragment >
          <div className="flex flex-col text-[26px]">
            <Button
              clickFunction={() => { wallet.activate(injected) }}
            >
              Metamask
            </Button>
            <Button
              clickFunction={() => { wallet.activate(walletconnect) }}
            >
              Walletconnect
            </Button>
            <Button
              clickFunction={() => { wallet.activate(walletlink) }}
            >
              Coinbase
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}