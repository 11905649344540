

export default function Button1({ children, clickFunction, }) {
    return (
        <button onClick={clickFunction}
            className={`inline-block text-[#3a1f3b] font-[600] my-2 px-4 md:px-14 py-1
                    shadow-[0px_4px_3px_rgba(0,0,0,0.8)] uppercase
                    rounded-[25px] font-pressio-condensed
                    hover:text-white
                    bg-gradient-to-b from-[#F9FEA5] via-[#FFEA36] via-[#FFBA36] to-[#9C5420]
                    `}
        >
            {children}
        </button>
    )
}