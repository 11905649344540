import React, { useState } from "react";
import { useQuery } from 'react-query';

import * as AssetAPI from '../utils/assetApi';
import classNames from "classnames";

export default function TheLabNFTs({ walletAddress, onSelect, onDeselect, displayAll, selectedAssets }) {

    // const [images, setImages] = useState([]);
    const [selected, setSelected] = useState(['', '', '', '']);
    // const [unselected, setUnselected] = useState(['', '', '', '']);

    const { isLoading, error, data } = useQuery(
        ['nfts', walletAddress],
        () =>
            AssetAPI.retrieveTokensForWallet(walletAddress, true).then((res) => {
                if (res.length > 0) {
                    setSelected([res[0]]);
                    onSelect([res[0]]);
                }
                // console.log(`Retrieved Asset Chunk =>`, res[0]['tokenId']??'');
                return res;
            })
    );

    if (error) return error;

    const getImageUrl = (tokenId) => {
        return AssetAPI.getImageUrl(tokenId);
    }

    function toggleImageSelected(tokenId) {
        let current = [...selected];
        let index = current.indexOf(tokenId);
        if (index >= 0) { // already selected so deselect
            onDeselect(tokenId); // deselect from main DrugWars page
            setSelected(['', '', '', '']);
        } else {
            setSelected([tokenId]);
            onSelect(tokenId);
        }
    }

    // const displayImage = (tokenId) => {
    //     return (
    //         <div key={'selected_' + tokenId} className='w-1/2'>
    //             <div className={classNames(
    //                 'relative overflow-hidden  ',
    //                 {
    //                     "border-2 border-white opacity-70 brightness-75 ":
    //                         selected.includes(tokenId)
    //                 }
    //             )}>
    //                 <img src={getImageUrl(tokenId)} className='rounded-md p-[2%]' />
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div>
            {isLoading ? (
                <div></div>
            ) : (
                <div>
                    <div className="grid auto-cols-[calc(36%-0.5rem*2)]
                    grid-cols-3 grid-flow-row overflow-x-auto
                    gap-2 w-full">
                        {data &&
                            (data).map((tokenId) => (
                                <div key={tokenId}
                                    className={selected.includes(tokenId) ? 'cursor-pointer relative rounded-md overflow-hidden shadow-[0_0px_6px_-0px_rgba(0,0,0,0.8)] m-1' : 'm-1 cursor-pointer relative rounded-md overflow-hidden'}
                                    onClick={() => toggleImageSelected(tokenId)}>

                                    <img src={getImageUrl(tokenId)} alt={`Drug receipt #${tokenId}`}
                                        className={classNames({
                                            "border-2 border-white rounded-md":
                                                selected.includes(tokenId)
                                        })}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            )}
        </div>
    )
}