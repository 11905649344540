// import _ from 'lodash';

const getHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};


const extractBody = (res) => {
  if (res.ok) {
    return res.json();
  } else {
    return res.text().then((text) => {
      console.log('Error: ', text);
      let errMessage;
      try {
        let err = JSON.parse(text);
        errMessage = err.message;
      } catch (error) { }
      throw new Error(errMessage || text);
    });
  }
};

// const BASE_URL = `http://localhost:${3001}`;
const BASE_URL = `https://api.drugreceipts.com`;

export const getImageUrl = (tokenId, type = 0, rand = false) => {
  // const url = `https://drugreceipts-5t931f461a.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_2160.png`;
  // const url = `https://drugreceipts-compressed-5t931f461b.s3.ap-southeast-1.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png`?${Math.random()}`;
  let url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
  switch (type) {
    case 1: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/transparent_image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 2: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_2160.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 3: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_3840.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 4: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_landscape.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 5: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_portrait.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
    case 6: {
      url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/Lab/3840_Extended_No_Glow`;
      break;
    }
    case 7: {
      url = `https://drugreceipts.s3.eu-west-1.amazonaws.com/transparent_token_image_with_glow/transparent_image_${tokenId}_2160.png_with_glow.png`;
      break;
    }
    default: {
      url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
      break;
    }
  }
  // const url = `https://drugreceipts-compressed-5t931f461b.s3.amazonaws.com/render_${tokenId}/image_${tokenId}_432.png${rand ? '?' + Math.random() : ''}`;
  return url;
}

export const retrieveTokensForWallet = (walletAddress, full) => {
  let url = `https://batcave.drx.store/api/wallet/${walletAddress}`;
  // let url = `${BASE_URL}/api/wallet/${walletAddress}`;

  if (full) { url += '/full'; }
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody(res);
  });
};

export const getTokenInfo = (tokenId) => {
  let url = `${BASE_URL}/api/token/${tokenId}`;
  return fetch(url, {
    method: 'GET',
    ...getHeaders(),
  }).then((res) => {
    return extractBody(res);
  });
};
