import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/DRx-Logo-Header-inspector.png";
import { FaTwitter, FaInstagram, FaFacebook, FaDiscord } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import discordImg from "../images/PurpleIcons/Discord.png";
import facebookImg from "../images/PurpleIcons/Facebook.png";
import instagramImg from "../images/PurpleIcons/Instagram.png";
import openseaImg from "../images/PurpleIcons/OpenSea.png";
import twitterImg from "../images/PurpleIcons/Twitter.png";

import InspectorSidebar from "./InspectorSidebar";

const SocialLinks = () => {
    const iconClassName = `inline-flex items-center justify-center text-white text-[17px]
    bg-[#78b2cf] rounded-[50%] leading-[17px] w-[34px] h-[34px]
    transition delay-0 hover:bg-[#5d8aa0] hover:scale-110 duration-200`;

    return (
        <div className="flex items-center content-center h-full gap-1 my-auto space-x-1 mt-2">
            <span>
                <a
                    className={iconClassName}
                    href="https://opensea.io/collection/drugreceipts"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={openseaImg}></img>
                </a>
            </span>
            <span>
                <a
                    className={iconClassName}
                    href="https://discord.gg/SUtQHy2CPM"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={discordImg}></img>
                </a>
            </span>
            <span>
                <a
                    className={iconClassName}
                    href="https://twitter.com/drugreceipts"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={twitterImg}></img>
                </a>
            </span>
            <span>
                <a
                    className={iconClassName}
                    href="https://www.instagram.com/drugreceipts/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={instagramImg}></img>
                </a>
            </span>
            <span>
                <a
                    className={iconClassName}
                    href="https://web.facebook.com/drugreceipts?_rdc=1&amp;_rdr"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={facebookImg}></img>
                </a>
            </span>
        </div>
    );
};

const MenuItems = ({ menuProps, externalLink, routes }) => {
    // const navigate = useNavigate();
    let items = menuProps.map((e, i) => {
        return (
            <div key={e}>
                {externalLink || menuProps[i] == "Home" || menuProps[i] == "Drug wars" || menuProps[i] == "Drug store" ? (
                    <a href={routes[i]} target="_blank" rel="noreferrer">
                        <div
                            className="text-xl font-pressio-condensed px-[13px] lg:py-[8px] text-[20px] 
              font-medium uppercase cursor-pointer text-[#a97fb1] hover:opacity-60"
                        >
                            {e}
                        </div>
                    </a>
                ) : menuProps[i] == "Drug inspector" ? (
                    <Link
                        to={routes[i]}
                        target="_self"
                        className={routes[i] === "#" ? "opacity-60" : ""}
                    >
                        <div
                            className="text-xl font-pressio-condensed px-[13px] py-[8px], text-[20px] 
            font-medium uppercase cursor-pointer text-[#29162D] sm:text-white hover:opacity-60"
                        >
                            {e}
                        </div>
                    </Link>
                ) : (
                    <Link
                        to={routes[i]}
                        target="_self"
                        className={routes[i] === "#" ? "opacity-60" : ""}
                    >
                        <div
                            className="text-xl font-pressio-condensed px-[13px] py-[8px], text-[20px] 
                font-medium uppercase cursor-pointer text-[#a97fb1]"
                        >
                            {e}
                        </div>
                    </Link>
                )}
            </div>
        );
    });
    return items;
}


export default function InspectorHeader({ menuItems, rightArea, showSocial, externalLink, routes }) {
    // const navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    const iconClassName = `inline-flex items-center justify-center text-[#a97fb1] 
  text-[25px] transition delay-0 hover:scale-110 duration-200`;

    return (
        <div className="absolute w-full">
            <div className="bg-[#29162D] px-5 ">
                <div className="flex max-w-[1140px] items-center justify-between py-3 mx-auto sm:px-0">
                    <div className="py-2">
                        <a href="https://drugreceipts.com/" target="_blank" rel="noreferrer">
                            <img src={logo} alt="logo" className="w-[168px] hover:cursor-pointer" />
                        </a>
                    </div>
                    <div className="hidden px-10 sm:flex justify-evenly grow items-center">
                        <MenuItems menuProps={menuItems} externalLink={externalLink} routes={routes} />
                    </div>
                    <div className="hidden sm:block">{rightArea && rightArea}</div>
                    {showSocial ? (
                        <div className="hidden sm:block">
                            <SocialLinks />
                        </div>
                    ) : null}
                    <div className="sm:hidden">
                        {!isSidebarOpen &&
                            <button className={iconClassName} onClick={toggleSidebar}>
                                <GiHamburgerMenu />
                            </button>}
                    </div>
                    {isSidebarOpen &&
                        <div>
                            <InspectorSidebar
                                toggleSidebar={toggleSidebar}
                                isSidebarOpen={isSidebarOpen}
                                menuItems={<MenuItems menuProps={menuItems} externalLink={externalLink} routes={routes} />}
                                socialIcons={<SocialLinks />}
                            />
                        </div>}
                </div>
            </div>
        </div>
    );
}
