import React from "react";


export default function MainPage({ children }) {

    return (
        <div className="lg:max-w-6xl mx-auto px-5 lg:px-0 min-h-[100vh] flex items-center">
            {children}
        </div>
    );
}
